<template>
  <div class="landing-page-tab">
    <h2 class="landing-page-header">
      {{ $lang.settingsPage_LandingPageTitle }}
    </h2>

    <p class="landing-page-description">
      <a class="landing-page-link" :href="landingPageURL" target="_blank">
        {{ landingPageURL }}
      </a>
    </p>
    <p class="landing-page-description">
      {{ $lang.settingsPage_LandingPageInstructions }}
    </p>

    <!-- Landing Page Fields -->
    <section class="landing-page-tab-section">
      <div class="form-input">
        <label class="form-label">{{
          $lang.settingsPage_LandingPageFieldsTitle
        }}</label>
        <common-input v-model="landingPageText[0]" />
      </div>
      <div class="form-input">
        <label class="form-label">{{
          $lang.settingsPage_LandingPageFieldsBody
        }}</label>
        <common-textarea v-model="landingPageText[1]" />
      </div>
      <div class="form-input">
        <label class="form-label">{{
          $lang.settingsPage_LandingPageFieldsFooter
        }}</label>
        <common-textarea v-model="landingPageText[2]" />
      </div>
    </section>

    <!-- Call-to-Action Buttons -->
    <section class="landing-page-tab-section">
      <h2 class="landing-page-tab-header">
        {{ $lang.settingsPage_LandingPageCallToActionButtons }}
      </h2>

      <p class="landing-page-description">
        {{ $lang.settingsPage_LandingPageCallToActionInstructions }}
      </p>
      <p class="landing-page-description example-text">
        {{ $lang.settingsPage_LandingPageCallToActionExamples }}
      </p>

      <ol class="landing-page-tab-cta-list">
        <li
          class="landing-page-tab-cta-list-item"
          v-for="(item, index) of landingPageLinks"
          :key="`landing-cta-buttons-${index}`">
          <div class="cta-buttons">
            <div class="label-url">
              <!-- label -->
              <common-input
                :placeholder="
                  $lang.settingsPage_LandingPageCallToActionButtonPlaceholder.format(
                    index + 1
                  )
                "
                v-model="item.name" />
              <!-- url -->
              <common-input
                :placeholder="
                  $lang.settingsPage_LandingPageCallToActionLinkPlaceholder.format(
                    index + 1
                  )
                "
                v-model="item.link" />
            </div>

            <common-button
              variant="icon"
              class="btn-delete-cta"
              @click="deleteCTAButton(index)">
              <ppicon name="close-circle" />
            </common-button>
          </div>
        </li>
      </ol>

      <common-button variant="primary" @click="addCTAButton" icon iconOnly>
        <template slot="icon">
          <ppicon name="plus" />
        </template>
      </common-button>
    </section>

    <section class="landing-page-tab-section">
      <h2 class="landing-page-tab-header">
        {{ $lang.settingsPage_LandingPageContactAmbassadors }}
      </h2>
      <div class="form-row">
        <label class="form-label" for="hide-send-message">
          {{ $lang.settingsPage_LandingPageHideSendMessage }}
        </label>
        <common-toggle
          id="hide-send-message"
          :value="!canSendMessage"
          @input="canSendMessage = !$event">
        </common-toggle>
      </div>
      <div class="form-row">
        <label class="form-label" for="hide-request-call">
          {{ $lang.settingsPage_LandingPageHideRequestCall }}
        </label>
        <common-toggle
          id="hide-request-call"
          :value="!canRequestCall"
          @input="canRequestCall = !$event">
        </common-toggle>
      </div>
    </section>

    <section class="landing-page-tab-section">
      <h2 class="landing-page-tab-header">
        {{ $lang.settingsPage_LandingPageRandomizeHeader }}
      </h2>

      <div class="form-row">
        <label class="form-label" for="randomize-ambassadors">
          {{ $lang.settingsPage_LandingPageRandomizeDescription }}
        </label>

        <common-toggle
          id="randomize-ambassadors"
          v-model="hasRandomizedAmbassadors">
        </common-toggle>
      </div>
    </section>

    <common-button variant="primary" class="btn-save" @click="saveLandingPage">
      {{ $lang.general_Save }}
    </common-button>
  </div>
</template>

<script>
  import { SET_BRANDING } from "@/store/actions.type.js";

  export default {
    props: ["branding"],
    data() {
      return {
        landingPageURL: null,
        landingPageLinks: [],
        landingPageText: [],
        canSendMessage: true,
        canRequestCall: true,
        hasRandomizedAmbassadors: false,
      };
    },
    created() {
      this.landingPageURL = this.branding.url;

      this.landingPageLinks =
        this.branding.landingPageLinks?.map((link) => {
          return { ...link };
        }) || [];

      this.landingPageText = [...this.branding.landingPage];
      this.canSendMessage = this.branding.canSendMessage;
      this.canRequestCall = this.branding.canRequestCall;
      this.hasRandomizedAmbassadors = this.branding.hasRandomizedAmbassadors;
    },
    methods: {
      deleteCTAButton(index) {
        this.landingPageLinks.splice(index, 1);
      },
      addCTAButton() {
        this.landingPageLinks.push({
          name: "",
          link: "",
        });
      },
      async saveLandingPage() {
        const data = {
          landingPage: this.landingPageText,
          landingPageLinks: this.landingPageLinks,
          canSendMessage: this.canSendMessage,
          canRequestCall: this.canRequestCall,
          hasRandomizedAmbassadors: this.hasRandomizedAmbassadors,
        };

        for (let item of this.landingPageLinks) {
          if (!item.name.length || !item.link.length) {
            this.$toasted.show(
              this.$lang.settingsPage_LandingPageMissingCTAFields,
              this.$toastedFailure
            );
            return;
          }
        }

        const response = await this.$store.dispatch(SET_BRANDING, data);
        if (response) {
          this.$toasted.show(
            this.$lang.settingsPage_LandingPageSaveSuccess,
            this.$toastedSuccess
          );
        } else {
          this.$toasted.show(
            this.$lang.general_ToastError,
            this.$toastedFailure
          );
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  * {
    font-size: 14px;
  }

  .landing-page-tab {
    color: #757e8c;
    max-width: 600px;
    padding-bottom: 1rem;
  }

  .landing-page-tab-header {
    color: $primary-text;
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 1.5rem;
  }

  .landing-page-tab-section {
    margin-bottom: 2rem;
  }

  .form-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .form-input {
    margin-bottom: 1rem;
  }

  .form-input > .form-label {
    margin-bottom: 0.5rem;
  }

  .landing-page-link {
    font-size: 1rem;
    font-weight: 500;
  }

  .cta-buttons {
    display: flex;
    align-items: flex-start;
  }

  .label-url {
    display: flex;
    flex-direction: column;
    flex: 1 1 30rem;
    gap: 0.5rem;
  }

  .btn-delete-cta {
    padding: 0.5rem;
    margin-top: 15px;
    margin-left: 5px;
  }

  .example-text {
    font-style: italic;
  }

  .landing-page-description {
    padding-bottom: 1rem;
  }

  .form-label {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 500;
  }

  .landing-page-tab-cta-list {
    list-style: unset;
    list-style-type: decimal;
    margin-left: 1rem;
  }

  .landing-page-tab-cta-list-item {
    margin-bottom: 1rem;
    padding-left: 1rem;
    font-weight: 500;
  }

  .btn-save {
    width: 10rem;
    margin: 2rem 0;
  }
</style>
